.main-refinance-now * {
  font-family: $fontLato !important;
}

.main-refinance-now {
  &.main-refinance-fb .MainLifeInsurance {
    background-image: url("../../images/refinance_now/bg-rectangle.png");
    background-color: transparent;

    &::after {
      display: none;
    }

    @media screen and (max-width: 767px) {
      padding-top: pxToRem(30);
      background-position: 32% center;

      .logo {
        margin-left: 0;
        margin-bottom: pxToRem(30);
      }

      .btn-reset {
        top: 30%;
      }
    }
  }

  .main-wrapper {
    display: flex;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 992px) {
      display: block;

      section {
        width: 100% !important;
      }
    }

    section {
      width: 50%;
    }

    .wrapper-info {
      width: 50%;
      align-self: center;

      h1 {
        font-size: pxToRem(55);
        color: #fff;
        font-weight: 700;
        text-align: left;
        margin-bottom: pxToRem(30);
      }

      h2 {
        font-size: pxToRem(35);
        color: #5abeff;
        text-align: left;
        font-weight: 600;
        margin-bottom: pxToRem(30);
        line-height: 1.2;
      }

      h3 {
        font-size: pxToRem(26);
        color: #fff;
        text-align: left;
        margin-bottom: pxToRem(30);
        position: relative;
        display: inline-block;
        padding-bottom: pxToRem(5);
        text-transform: uppercase;
        font-weight: 700;

        &::after {
          content: "";
          bottom: 0;
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          border-bottom: 2px solid #5abeff;
        }
      }

      p {
        max-width: 500px;
        font-size: pxToRem(22);
        color: #fff;
        text-align: left;
        margin-bottom: pxToRem(20);
      }

      ul {
        li {
          padding-left: pxToRem(40);
          position: relative;
          font-size: pxToRem(18);
          font-weight: 500;
          color: #fff;
          padding-bottom: pxToRem(15);
          text-align: left;

          &::before {
            content: "";
            height: pxToRem(25);
            width: pxToRem(25);
            background-image: url("../../images/refinance_now/check.png");
            background-position: center;
            background-size: cover;
            background-size: pxToRem(20);
            position: absolute;
            left: 0;
            top: 0;
            background-repeat: no-repeat;
          }
        }
      }

      @media screen and (max-width: 991px) {
        width: 100% !important;
        margin-bottom: 40px;

        h1 {
          font-size: pxToRem(40);
        }

        h2 {
          font-size: pxToRem(25);
        }

        h3 {
          font-size: pxToRem(20);
        }

        p {
          font-size: pxToRem(17);
        }

        ul {
          li {
            font-size: pxToRem(16);

            &::before {
              top: pxToRem(3);
            }
          }
        }
      }

      @media screen and (max-width: 767px) {
        h1 {
          font-size: pxToRem(29);
        }

        h2 {
          font-size: pxToRem(20);
          margin-bottom: pxToRem(20);
        }

        h3 {
          font-size: pxToRem(16);
          margin-bottom: pxToRem(20);
        }
      }
    }

    .content-box {
      h2 {
        @media screen and (min-width: 992px) {
          white-space: pre-line;
        }

        font-size: pxToRem(26);
        font-weight: 700;

        span {
          font-weight: 600;
          display: inline-block;
        }

        @media screen and (max-width: 991px) {
          font-size: pxToRem(20);
        }
      }

      .input__box {
        .input__label {
          font-weight: 500;
        }

        input,
        textarea {
          border-radius: 2px;
        }
      }

      .checkbox-button {
        border-radius: 2px;

        .checkbox__label {
          text-align: center;
          padding-left: pxToRem(30);
          display: block;
        }
      }

      @media screen and (max-width: 767px) {
        .group-rating {
          img {
            height: pxToRem(20);
          }
        }

        .number {
          margin-bottom: 0;
        }

        .number,
        .text {
          font-size: pxToRem(14);
        }
      }
    }
  }

  .title {
    margin-bottom: pxToRem(15);
    font-family: $fontLato;
    font-size: pxToRem(32);
    font-weight: 900;

    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
      letter-spacing: -0.05rem;
    }
  }

  .btn-group-option {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 769px) and (max-width: 1100px) {
      transform: scale(0.8);
      -webkit-transform: scale(0.8);
      margin-left: pxToRem(-30);
    }

    @media screen and (max-width: 600px) {
      transform: scale(0.68);
      -webkit-transform: scale(0.68);
      margin-left: -28%;
      margin-bottom: 0;
    }

    .item {
      width: pxToRem(228);
      height: pxToRem(175);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn-box {
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
        width: pxToRem(191);
        height: pxToRem(140);
        transition: all 0.3s;
        visibility: visible;
        padding: pxToRem(20) pxToRem(15);
        text-align: center;

        .purchase {
          margin-bottom: pxToRem(8);

          img {
            width: 70px;
            height: 65px;
            -webkit-transition: transform 0.5s ease;
            transition: transform 0.5s ease;
          }

          img.active {
            display: none;
          }
        }

        .refinance {
          margin-bottom: pxToRem(8);

          img {
            width: 70px;
            height: 70px;
            -webkit-transition: transform 0.5s ease;
            transition: transform 0.5s ease;
          }

          img.active {
            display: none;
          }
        }

        &.active,
        &:hover {
          box-shadow: 0px pxToRem(10) pxToRem(40) pxToRem(-10)
            rgb(46 159 233 / 36%);

          img.active {
            display: inline-block;
          }

          img.normal {
            display: none;
          }

          p {
            color: #2e9fe9;
            font-weight: 900;
          }
        }

        p {
          margin: 0;
          font-size: pxToRem(17);
          font-weight: 500;
          color: #212121;
          transition: all 0.3s;
        }
      }
    }
  }
}

.title-domain {
  margin-top: pxToRem(60);

  h2 {
    margin-bottom: pxToRem(15);
    font-family: $fontLato;
    font-size: pxToRem(32);
    font-weight: 900;

    span {
      font-size: pxToRem(32);
      color: #2496e0;
      font-weight: 900;

      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
        display: block;
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: pxToRem(40);

    h2 {
      font-size: 1.5rem;
    }
  }
}

.makescents {
  .bg-image {
    background-color: #003362;

    img {
      display: block;
      box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    }
  }

  .list-box-right {
    padding: pxToRem(15) 0;

    @media screen and (max-width: 767px) {
      margin-top: pxToRem(20);
    }

    ul {
      li {
        padding-left: pxToRem(50);
        position: relative;

        &::before {
          content: "";
          height: pxToRem(30);
          width: pxToRem(30);
          background-image: url("../../images/refinance_now/check.png");
          background-position: center;
          background-size: cover;
          background-size: pxToRem(25);
          position: absolute;
          left: 0;
          top: pxToRem(-3);
          background-repeat: no-repeat;
        }

        @media screen and (max-width: 767px) {
          padding-left: pxToRem(35);

          &::before {
            height: pxToRem(20);
            width: pxToRem(20);
            top: 0;
            background-size: pxToRem(18);
          }
        }
      }
    }

    h3 {
      color: #000000;
      font-weight: 900;
      list-style: 1.6;
    }

    .box-title {
      font-size: pxToRem(25);
      margin-bottom: pxToRem(40);

      @media screen and (max-width: 767px) {
        font-size: 1.33rem;
      }
    }

    .list-box-item {
      margin-bottom: pxToRem(30);

      h3 {
        font-size: pxToRem(18);
        margin-bottom: pxToRem(10);

        @media screen and (max-width: 767px) {
          font-size: pxToRem(16);
        }
      }

      p {
        color: #414141;
        font-size: pxToRem(16);

        @media screen and (max-width: 767px) {
          font-size: pxToRem(14);
        }
      }
    }
  }
}

.home-loan-item {
  margin-bottom: 30px;
  text-align: center;

  &:hover {
    .box-images .img {
      transform: scale(1.05);
      -webkit-transform: scale(1.05);
    }
  }

  .box-images {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    .img {
      width: 100%;
      -webkit-transition: transform 0.5s ease;
      transition: transform 0.5s ease;
      cursor: pointer;
      position: relative;
      z-index: 2;
      padding: 20px 30px;
    }

    @media screen and (max-width: 767px) {
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
    }
  }

  .item-title {
    margin-top: pxToRem(20);
    margin-bottom: pxToRem(15);
    font-size: pxToRem(18);
    color: #212121;
    text-transform: uppercase;
    font-weight: 900;

    @media screen and (max-width: 767px) {
      font-size: pxToRem(14);
      margin-top: -20px;
    }
  }

  .desc {
    color: #414141;
    font-size: pxToRem(15.4);
    padding: 0 20px;

    @media screen and (max-width: 767px) {
      font-size: pxToRem(14);
      max-width: 300px;
      margin: auto;
    }
  }
}

.customers-bg {
  background: linear-gradient(-90deg, #59bcfe, #2e9fe9);
  padding-bottom: 15rem;
}

.customers {
  margin-top: -15rem;
  padding-bottom: 4rem;

  .customers-loan-item {
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    padding: pxToRem(35) pxToRem(20);

    .customers-box {
      margin-bottom: pxToRem(40);
      min-height: calc(100% - 100px);

      .item-title {
        margin-bottom: pxToRem(20);
        color: #2e9fe9;
        font-size: pxToRem(22);
        font-weight: 900;

        @media screen and (max-width: 767px) {
          font-size: pxToRem(18);
          margin-bottom: pxToRem(15);
        }
      }

      .desc {
        font-size: pxToRem(15);
        color: #414141;
        line-height: 1.7;
        text-align: justify;
      }
    }

    .group-rating {
      img {
        max-height: 25px;
        margin-bottom: pxToRem(5);
      }

      .text-author {
        color: #2e9fe9;
        font-weight: 500;
        font-size: pxToRem(15.31);
      }
    }
  }

  @media screen and (max-width: 767px) {
    background: linear-gradient(-90deg, #59bcfe, #2e9fe9);
    padding-bottom: 0;

    > .row .col-12.col-md-4.mb-4.col {
      &:last-child {
        margin-bottom: -4rem !important;
      }
    }

    .customers-loan-item {
      padding: pxToRem(20);

      .customers-box {
        margin-bottom: pxToRem(15);

        .desc {
          font-size: pxToRem(14);
        }
      }

      .group-rating {
        img {
          max-height: 20px;
        }

        .text-author {
          font-size: pxToRem(14);
        }
      }
    }
  }
}

.group-rating-top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  font-size: pxToRem(17);
  color: #ffffff;

  img {
    margin-right: 10px;
    max-height: pxToRem(30);
  }

  @media screen and (max-width: 767px) {
    display: block;
    text-align: center;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 5px;
    }
  }
}

.personalised {
  .group-btn-option {
    display: flex;
    align-items: center;
    justify-content: center;

    .btnPrimary {
      margin: 0 pxToRem(10);
      text-transform: capitalize !important;
      max-width: pxToRem(150);
      min-width: pxToRem(200);
    }
  }

  @media screen and (max-width: 767px) {
    padding-top: 7rem;

    .group-btn-option {
      .btnPrimary {
        min-width: pxToRem(160);
      }
    }
  }
}

.life-insurance footer.footer-life-insurance .container.w-100 {
  max-width: 1140px !important;
}

.life-insurance footer.footer-life-insurance.d-block {
  display: block !important;
}

.lock {
  display: flex;
  align-items: center;
  margin-top: pxToRem(30);
  justify-content: center;

  p {
    color: #ffffff;
    font-weight: 500;
    font-size: pxToRem(13);
  }

  img {
    width: pxToRem(15);
    margin-right: pxToRem(10);
  }

  @media screen and (max-width: 767px) {
    margin-top: pxToRem(20);

    p {
      font-size: pxToRem(9);
    }

    img {
      width: pxToRem(10);
      margin-right: pxToRem(5);
    }
  }
}

.opacity-1 {
  opacity: 1 !important;
}

.text-heading {
  font-family: $fontLato;
  font-size: pxToRem(18);
  margin-bottom: pxToRem(30);
}
