.checkbox-button {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  max-width: 100%;
  z-index: 2;
  border: pxToRem(1) solid #b0b0b0;
  height: pxToRem(60);
  display: block;
  border-radius: pxToRem(7);
  font-size: pxToRem(16);
  padding: pxToRem(10) pxToRem(15);
  margin-bottom: pxToRem(15);
  background-color: #f6fbff;

  &.label-uppercase {
    .checkbox .checkbox__label span {
      text-transform: capitalize;
    }

    .checkbox__label {
      padding-left: 3.125rem !important;
      text-align: left !important;
    }
  }

  .checkbox .checkbox__label span {
    font-weight: 700;
    text-transform: uppercase;
  }

  .icon-box {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 36px;
  }

  @media screen and (max-width: 640px) {
    height: pxToRem(50);
    border-radius: pxToRem(6);
    font-size: pxToRem(16);
    padding: pxToRem(10) pxToRem(15);
    background-color: #f6fbff;

    .checkbox__label {
      position: relative;
      top: pxToRem(-2);
    }
  }

  &.checkboxSwitch {
    height: pxToRem(52);
    padding: pxToRem(6) pxToRem(15);

    .checkbox--checked .switch {
      background-color: #2196F3 !important;
      border-color: #2e9fe9;

      .slider {
        &:before {
          -webkit-transform: translateX(35px);
          -ms-transform: translateX(35px);
          transform: translateX(35px);
        }
      }
    }

    .switch {
      right: 0;
      display: inline-block;
      width: 52px;
      height: 22px;
      position: absolute;
      top: 50%;
      z-index: -1;
      background-color: #e6f5ff;
      box-shadow: 1px 0px 4px #1a77b4;
      border: 1px solid #e6f5ff;
      border-radius: 20px;
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);

      .slider {
        &:before {
          position: absolute;
          content: "";
          height: 22px;
          width: 22px;
          left: -2px;
          bottom: -1px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
          border: 2px solid #2e9fe9;
        }
      }
    }

    .checkbox__label {
      padding-left: 0;
      width: 100%;

      span {
        text-transform: none;
      }

      &::before,
      &::after {
        display: none;
      }
    }

    @include max-screen($screen-sm) {
      .checkbox--checked .switch {
        .slider {
          &:before {
            -webkit-transform: translateX(30px);
            -ms-transform: translateX(30px);
            transform: translateX(30px);
          }
        }
      }

      .switch {
        width: 45px;
        height: 20px;
        border-radius: 16px;

        .slider {
          &:before {
            height: 20px;
            width: 20px;
          }
        }
      }

      .checkbox__label span {
        font-size: 16px;
      }
    }

    @media screen and (max-width: 640px) {

      height: 3rem;
      border-radius: 0.375rem;
      font-size: 1rem;
      padding: 0.625rem 0.9375rem;

      .checkbox__label span {
        font-size: 18px;
      }
    }
  }
}