.life-insurance.home-loan-fb {
  .bankProviders {
    position: relative;
    z-index: 1;
  }

  * {
    font-family: 'Poppins' !important;
  }

  .welcomeLifeInsurance {
    margin-top: 0;
    padding-top: pxToRem(150);
  }

  .btn-reset {
    max-width: pxToRem(30);
  }

  .header-wrap {
    .logo {
      max-width: pxToRem(225);
    }

    .container {
      display: flex;
      justify-content: space-between;
    }

    .imgLogoGlobal_sign {
      position: relative;
      top: pxToRem(5);

      .logo__img {
        display: block;
        max-width: pxToRem(130);
      }
    }
  }

  .MainLifeInsurance {
    background-color: #fafafa;
  }

  .welcomeLifeInsurance {

    @media screen and (min-width:992px) {
      h1 {
        font-size: pxToRem(36);
        letter-spacing: -0.8px !important;
      }
    }

    ul {
      padding-top: pxToRem(20);
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        font-size: pxToRem(20);
        font-weight: 600;
        color: #212121;
        padding-left: pxToRem(35);
        margin-right: pxToRem(50);
        // background-image: url('../../images/tick.png');
        background-position: center left;
        background-size: pxToRem(21);
        background-repeat: no-repeat;
      }
    }
  }


  .content-box {
    margin-top: pxToRem(30);
    margin-bottom: pxToRem(30);
    border-style: dotted;
    border-width: pxToRem(5);
    border-color: #5D2E8C;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: pxToRem(10);
    position: relative;

    h2 {
      @media screen and (min-width:767px) {
        white-space: pre-wrap;
      }
    }

    .group-rating {
      max-width: pxToRem(200);
      margin: pxToRem(20) auto pxToRem(30) auto;
      text-align: center;

      img {
        max-width: pxToRem(150);
      }

      p {
        font-size: pxToRem(18);
        font-weight: 500;
        text-align: center;
      }
    }

    .btn-group-gender {
      width: 100%;
      padding-left: pxToRem(15);
      padding-right: pxToRem(15);

      .item {
        width: 100%;

        .btn-box {
          width: 100%;
          height: 100%;
          padding: pxToRem(18) pxToRem(30);
          border-color: #dff2ff;
          color: #364047;
          background-color: #dff2ff;
          font-size: pxToRem(18);
          font-weight: 600;
          text-transform: uppercase;
          border-radius: pxToRem(4);
          text-align: center;

          &:hover,
          &.active {
            background-color: #2f9fe9;
            color: #ffffff;
          }
        }
      }

      @media screen and (max-width:767px) {
        padding-left: pxToRem(0);
        padding-right: pxToRem(0);

        .item {
          .btn-box {
            padding: pxToRem(12) pxToRem(15);
            font-size: pxToRem(16);
          }
        }
      }
    }

    .input__box {
      .input-change {
        font-weight: 500;
      }

      .input__label {
        font-weight: 400 !important;
        top: pxToRem(7);
      }
    }

    .btn-box.checkbox-button {
      width: 100%;
      padding: pxToRem(18) pxToRem(30);
      border-color: #dff2ff;
      color: #364047;
      background-color: #dff2ff;
      font-size: pxToRem(18);
      font-weight: 600;
      text-transform: uppercase;
      border-radius: pxToRem(4);
      text-align: center;
      outline: none !important;
      box-shadow: none !important;
      line-height: 1.2;

      &:hover,
      &.active {
        background-color: #2f9fe9;
        color: #ffffff;
      }

    }

    @media screen and (max-width:767px) {
      .btn-box.checkbox-button {
        height: auto;
        padding: pxToRem(14) pxToRem(5);
        font-size: pxToRem(15);
      }

      p {
        font-size: pxToRem(14);
        padding: 0 pxToRem(10);
      }

      div {
        font-size: pxToRem(14);
      }
    }
  }

  .btnPrimary.life {
    background-image: url("../../images/home-loan-fb/down-chevron.png");
    background-position: center right pxToRem(15);
    background-size: pxToRem(12);
    background-repeat: no-repeat;
    width: 100%;
    max-width: pxToRem(600);

    &.max-700 {
      max-width: pxToRem(700);

      @media screen and (max-width: 767px) {
        height: auto;
        line-height: 1.2;
        font-size: pxToRem(16);
        padding-left: pxToRem(15);
        padding-right: pxToRem(30);
      }
    }

    &:hover {
      background-color: #2e9fe9 !important;
      border-color: #2e9fe9 !important;
      opacity: 0.9;
    }
  }

  .postcode {

    .wForm-input {
      z-index: 1;
    }
  }

  .step-loading {
    .wForm-input {
      min-height: pxToRem(250);
    }
  }

  .list-occupation {
    li {
      font-weight: 500;
    }
  }

  .progress {
    max-width: 90%;
    margin: pxToRem(40) auto;
    background-color: #dff2ff;

    .progress-bar {
      background-color: #2f9fe9;
    }
  }

  .elementor-icon {
    font-size: pxToRem(120);
    color: #F27629;
    border-color: #F27629;
    position: absolute;
    right: 0;
    top: pxToRem(-56);

    svg {
      width: pxToRem(120);
      height: pxToRem(120);
      position: relative;
      display: block;
      transform: rotate(41deg);
      -webkit-transform: rotate(41deg);
      fill: #F27629;
    }
  }

  @media screen and (max-width: 1200px) {
    .elementor-icon {
      font-size: pxToRem(80);
      top: pxToRem(-20);

      svg {
        width: pxToRem(80);
        height: pxToRem(80);
      }
    }

    .header.header-wrap {
      .container {
        padding-right: pxToRem(100);
      }
    }

    .welcomeLifeInsurance h1 {
      font-size: 1.6rem;
      padding: 0 pxToRem(5);
    }

    .welcomeLifeInsurance {
      p {
        font-size: 1rem;
        line-height: 1.2;
      }

      ul {
        li {
          font-size: 1rem;
          padding-left: pxToRem(20);
          margin-right: pxToRem(15);
          background-size: pxToRem(15);
        }
      }
    }

    h2 {
      font-size: 1.5rem;
    }
  }

  .check-phone {
    z-index: 1;
  }

  @media screen and (max-width: 767px) {
    .elementor-icon {
      font-size: pxToRem(80);
      top: pxToRem(-28);
      right: pxToRem(-2);
    }

    .progress {
      max-width: 100%;
      margin: pxToRem(30) auto;
    }

    .welcomeLifeInsurance {
      padding-top: pxToRem(100);
    }

    .header.header-wrap {
      .container {
        padding-right: pxToRem(50);
        padding-left: 0;

        .logo {
          max-width: pxToRem(130);
        }

        .imgLogoGlobal_sign {
          max-width: pxToRem(80);
        }
      }
    }

    .welcomeLifeInsurance h1 {
      font-size: 1.25rem;
    }

    .welcomeLifeInsurance {
      p {
        font-size: 0.9rem;
      }

      ul {
        display: block;

        li {
          font-size: 1.1rem;
          margin-bottom: pxToRem(10);
          display: inline-block;
        }
      }
    }

    h2 {
      font-size: 1.15rem;
      padding-left: pxToRem(5);
      padding-right: pxToRem(5);
    }

    .content-box {
      margin-bottom: 0;
      border-width: pxToRem(3);
    }
  }
}

.Calculating {
  font-size: pxToRem(22);
  font-weight: 500;
  text-align: center;
  color: #364047;
}

.why-have-the-call {
  text-align: center;
  padding: pxToRem(80) pxToRem(60) pxToRem(20) pxToRem(60);

  .imgLogoGlobal_sign {
    max-width: pxToRem(120);
    margin: 0 auto pxToRem(30) auto;
  }

  p {
    font-size: pxToRem(18);
    font-weight: 400;
    color: #364047;
    margin-bottom: pxToRem(20);
  }

  @media screen and (max-width: 1200px) {
    padding: pxToRem(60) pxToRem(30) pxToRem(20) pxToRem(30);

    p {
      font-size: pxToRem(16);
    }
  }

  @media screen and (max-width: 767px) {
    padding: pxToRem(50) pxToRem(15) pxToRem(15) pxToRem(15);

    p {
      padding: 0 !important;
    }
  }
}