html.fonts100 {
  font-size: 100%;
}

.ico-look {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: pxToRem(30);

  img {
    width: pxToRem(13);
    height: pxToRem(15);
    margin-right: pxToRem(5);
  }

  span {
    font-family: $fontLato;
    font-size: pxToRem(14);
    font-weight: 700 !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #212121;
  }
}

.life-insurance {
  &__MainCt {
    padding-top: pxToRem(200);
  }

  .header {
    padding: pxToRem(20);
    height: pxToRem(95);
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    display: block;
    border-bottom: pxToRem(1) solid #cdcdcd;

    &.sticky {
      .header-humanoid {
        bottom: 6px;
        transform: scale(0.9);
      }
    }

    .logo {
      text-align: left;
      max-width: pxToRem(130);

      img {
        height: pxToRem(60);
      }
    }

    .btn-reset {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: pxToRem(45);
      margin-top: pxToRem(-15);
      opacity: 0.5;
      z-index: 999;

      img {
        width: pxToRem(22);
        height: pxToRem(26);
      }
    }
  }

  .header-wrap .header-humanoid {
    width: pxToRem(80);
    height: pxToRem(80);
    position: absolute;
    left: 50%;
    margin-left: pxToRem(-40);
    bottom: pxToRem(-40);
    -webkit-transition: bottom 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -moz-transition: bottom 0.2s ease-in-out, -moz-transform 0.2s ease-in-out;
    -o-transition: bottom 0.2s ease-in-out, -o-transform 0.2s ease-in-out;
    transition: bottom 0.2s ease-in-out, transform 0.2s ease-in-out;

    .avatar {
      background: center center no-repeat;
      background-image: url("../../images/life/Maya.jpg");
      display: inline-block;
      border-radius: 50%;
      flex-shrink: 0;
      flex-grow: 0;
      -webkit-transition: width 0.15s, height 0.15s, background-size 0.15s;
      -moz-transition: width 0.15s, height 0.15s, background-size 0.15s;
      -o-transition: width 0.15s, height 0.15s, background-size 0.15s;
      transition: width 0.15s, height 0.15s, background-size 0.15s;
      border: 1px solid #eee;

      &.avatar2 {
        background-image: url("../../images/life/Maya2.png");
      }
    }

    .avatar.med {
      width: pxToRem(80);
      height: pxToRem(80);
      background-size: pxToRem(80) pxToRem(80);
    }
  }
  .sub-title {
    color: #001d30;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: 500;
    font-family: "Roboto Slab";
  }
  .welcomeLifeInsurance {
    margin-top: pxToRem(70);

    .logo {
      margin-bottom: pxToRem(120);
      width: pxToRem(140);
      margin-left: auto;
      margin-right: auto;
    }

    h1 {
      color: $black-active;
      text-align: center;
      font-size: pxToRem(40);
      font-weight: $medium;
      font-family: $fontRobotoSlab;
      margin-bottom: pxToRem(25) !important;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: center;

      &.mb-1 {
        margin-bottom: pxToRem(10) !important;
      }

      span {
        font-weight: $medium;
        font-family: $fontRobotoSlab;
        font-size: inherit;
      }
    }

    p {
      font-family: $fontLato;
      font-size: pxToRem(23);
      font-weight: $regular;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.96;
      letter-spacing: normal;
      text-align: center;
      color: #212121;
    }
  }

  h2 {
    color: $black-active;
    text-align: center;
    font-size: pxToRem(30);
    line-height: pxToRem(35);
    font-weight: $medium;
    font-family: $fontRobotoSlab;
    margin-bottom: pxToRem(60);

    span {
      font-size: pxToRem(22);
      font-weight: 500;
    }
  }

  .title-question-left h3 {
    color: $black-active;
    font-size: pxToRem(26);
    line-height: pxToRem(30);
    font-weight: $medium;
    font-family: $fontRobotoSlab;

    @media screen and (max-width: 992px) {
      font-size: pxToRem(23);
    }

    @media screen and (max-width: 640px) {
      font-size: pxToRem(20);
    }
  }

  .info-customer {
    margin-bottom: pxToRem(25);
    max-width: pxToRem(510);
    margin-left: auto;
    margin-right: auto;

    .wForm-input {
      padding: 0 pxToRem(7);
    }

    &.refinance-fb {
      max-width: pxToRem(650);
    }
  }

  .text-error {
    text-align: center;

    p {
      font-size: pxToRem(14) !important;
      color: red !important;
      font-weight: 500 !important;
      display: flex;
      justify-content: center;
      align-items: center;

      &.font-18 {
        font-family: "Lato";
        font-size: 1.125rem !important;
        font-weight: bold !important;
        text-align: center;

        @include max-screen($width-site) {
          font-size: 1rem !important;
        }
      }
    }

    img {
      width: pxToRem(20);
      height: pxToRem(19);
      margin-right: pxToRem(15);
    }
  }
}

.btnPrimary.life {
  box-shadow: 0px 10px 40px -10px rgba($btn-bg, 0.36);
  background-color: $btn-bg;
  color: $white;
  border-color: $btn-bg;
  font-size: pxToRem(20);
  line-height: pxToRem(40);
  font-family: $fontLato;
  font-weight: 500;
  text-transform: uppercase;
  // height: pxToRem(60);
  width: pxToRem(235);
  min-width: pxToRem(200);
  margin: pxToRem(50) auto 0 auto;
  -moz-transition: all 0.2s, -moz-transform 0.5s;
  -o-transition: all 0.2s, -o-transform 0.5s;
  -webkit-transition: all 0.2s, -webkit-transform 0.5s;
  transition: all 0.2s, transform 0.5s;

  &:hover {
    background-color: $black-active;
    border-color: $black-active;
  }

  &:disabled {
    background-color: $btn-disable;
    border-color: $btn-disable;
  }
}

.life-insurance {

  // InputCustom2 
  .input {
    &__box {
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        left: pxToRem(15);
        margin-top: pxToRem(-13);

        .iconEmail {
          width: pxToRem(26);
          height: pxToRem(20);
          opacity: 0.6;

          &.imgLocation {
            height: pxToRem(21);
          }

          &.dolor {
            width: pxToRem(15);
            height: pxToRem(30);
            margin-top: -2px;
            opacity: 1;
          }
        }
      }

      &.email {
        .input-change {
          padding-left: pxToRem(55);
        }

        .input__label {
          left: pxToRem(56);
        }
      }

      .check-phone {
        right: pxToRem(10);
        left: auto;

        img.iconEmail {
          opacity: 1;
        }
      }

      input,
      textarea {
        border: pxToRem(1) solid $color-enquiry-action;
        height: pxToRem(60);
        display: block;
        border-radius: pxToRem(7);
        font-size: pxToRem(18);
        padding: pxToRem(15);
        padding-bottom: pxToRem(8);
        color: $black-active;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: $bold;
        -webkit-transition: width 0.15s, height 0.15s, background-size 0.15s;
        -moz-transition: width 0.15s, height 0.15s, background-size 0.15s;
        -o-transition: width 0.15s, height 0.15s, background-size 0.15s;
        transition: width 0.15s, height 0.15s, background-size 0.15s;
        outline: none;
        background-color: transparent;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
        }

        &.value {
          padding-top: pxToRem(20);
        }

        &:focus {
          border: pxToRem(2) solid $blue;
        }
      }

      textarea {
        min-height: pxToRem(60);
        white-space: pre-line;
        overflow: hidden;
        text-overflow: inherit;
        height: auto !important;
        // -moz-user-select: -moz-none;
        // -khtml-user-select: none;
        // -webkit-user-select: none;
        resize: none;
        // -ms-user-select: none;
        // user-select: none;

        &.value {
          padding-top: pxToRem(23);
        }
      }

      .input__label {
        position: absolute;
        top: pxToRem(5);
        left: pxToRem(17);
        -webkit-transition: all 0.15s ease-out;
        -moz-transition: all 0.15s ease-out;
        -o-transition: all 0.15s ease-out;
        transition: all 0.15s ease-out;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transform-origin: 0 100%;
        -webkit-transform: translateY(12px);
        -moz-transform: translateY(12px);
        -ms-transform: translateY(12px);
        -o-transform: translateY(12px);
        transform: translateY(12px);
        will-change: transform;
        cursor: text;
        color: $color-enquiry-action;
        text-transform: uppercase;
        font-family: $fontLato;
        font-size: pxToRem(15);
        font-weight: $bold;
        // z-index: -1;

        &.active {
          font-size: pxToRem(14);
          transform: scale(0.75);
          pointer-events: none;
        }

        &.multiline {
          top: pxToRem(8);

          &.active {
            top: pxToRem(4);
          }
        }
      }

    }
  }

  .formContent-life-insurance {
    h2 {
      margin-bottom: pxToRem(30);
    }

    p {
      font-size: pxToRem(20);
      line-height: pxToRem(35);
      color: #212121;
      font-family: $fontLato;
      font-weight: $regular;
    }
  }

  .formContent-step-second {

    h2 {
      margin-bottom: pxToRem(30);
    }

    p {
      font-size: pxToRem(20);
      line-height: pxToRem(35);
      color: #212121;
      font-family: $fontLato;
      font-weight: $regular;
    }

    .checkBox {
      margin-top: pxToRem(20);
    }
  }

  //  FOOTER
  footer.footer-life-insurance {
    text-align: center;
    background-color: $white;
    padding-bottom: 0;
    padding-top: pxToRem(60);

    @media screen and (min-width: 2000px) {
      position: fixed;
      width: 100%;
      bottom: pxToRem(30);
    }

    .container {
      max-width: pxToRem(825);
    }

    p {
      font-size: pxToRem(12);
      font-weight: 200;
      line-height: pxToRem(15);
      font-family: $fontLato;
      color: #a6a6a6;
      margin-bottom: pxToRem(15);
      letter-spacing: pxToRem(-0.45);
      text-align: justify;
      text-align-last: center;
    }
  }

  .formContent-step-third {
    .info-customer {
      max-width: pxToRem(300);
    }
  }

  .in-progress {
    position: relative;
    visibility: visible;

    .spinner-border {
      display: block;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      border-radius: 100%;
      box-sizing: border-box;
      margin-top: -12.5px;
      margin-left: -12.5px;
      width: 25px;
      height: 25px;
      border: pxToRem(0.4) solid rgba(255, 255, 255, 0.15);
      border-top-color: rgba(255, 255, 255, 1);
      left: 25px;
    }
  }

  .formContent-step-four {
    .info-customer {
      max-width: pxToRem(400);

      .showValid {
        .group-gender {
          margin-bottom: pxToRem(30);
        }
      }
    }

    .group-birth {
      display: flex;
      align-items: center;
      border: pxToRem(2) solid $color-enquiry-action;
      border-radius: pxToRem(6);
      -webkit-transition: all 0.15s ease-out;
      -moz-transition: all 0.15s ease-out;
      -o-transition: all 0.15s ease-out;
      transition: all 0.15s ease-out;
      &.group-birth {
        .input  {
          width: 100% !important;
          text-align: left;
          .input-change {
            text-align: left !important;
          }
          &__label{
            left: 38% !important;
            white-space: nowrap;
          }
        }
      }
      &:focus,
      &:hover {
        border-color: #2e9fe9;
      }

      .birth-label {
        white-space: nowrap;
        border: pxToRem(1) solid $color-enquiry-action;
        padding: pxToRem(5) pxToRem(27);
        text-align: center;
        font-family: $fontLato;
        font-weight: 700;
        color: $black-light-2;
        font-size: pxToRem(17);
        height: pxToRem(60);
        display: flex;
        align-items: center;
        background-color: $color-progress1;
        border-top-left-radius: pxToRem(6);
        border-bottom-left-radius: pxToRem(6);
        border-left: 0;
        border-top: 0;
        border-bottom: 0;
      }

      .input {
        width: pxToRem(84);

        .input-change {
          text-align: center;
          border-radius: 0;
          height: pxToRem(60);
          border-top: 0;
          border-bottom: 0;

          &:focus {
            border-color: $color-enquiry-action;
          }
        }

        .dateDay {
          &.input-change {
            border-right: 0;
            border-left: 0;
          }
        }

        &:last-child {
          width: pxToRem(94);

          .input-change {
            text-align: center;
            border-top-right-radius: pxToRem(6);
            border-bottom-right-radius: pxToRem(6);
            border-left: 0;
            border-right: 0;
          }
        }

        .input__label {
          left: 50%;
          transform: translate(-50%, 12px);

          &.active {
            top: pxToRem(-6);
          }
        }
      }
    }

    .group-gender {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: pxToRem(38) 0;

      .checkbox {
        &:first-child {
          margin-right: pxToRem(50);
        }
      }
    }
  }

  .wForm-input {
    position: relative;
  }

  .list-occupation {
    position: absolute;
    left: 0;
    width: pxToRem(503);
    // max-height: pxToRem(320);
    // overflow: auto;
    z-index: 99;
    background-color: #f7f7f7;
    border: 1px solid #dfdfdf;
    border-radius: pxToRem(6);
    top: 100%;
    margin-top: pxToRem(1);

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
      background-color: #dfdfdf;
    }

    &::-webkit-scrollbar {
      width: pxToRem(5);
      background-color: #dfdfdf;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-light13;
      border: pxToRem(5) solid $color-light13;
      border-radius: pxToRem(2);
    }

    li {
      font-family: $fontLato;
      font-size: pxToRem(17);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.47;
      letter-spacing: normal;
      text-align: left;
      color: #212121;
      border-bottom: 1px solid #dfdfdf;
      padding: pxToRem(0) pxToRem(30) pxToRem(0) pxToRem(60);
      cursor: pointer;
      -webkit-transition: all 0.15s ease-out;
      -moz-transition: all 0.15s ease-out;
      -o-transition: all 0.15s ease-out;
      transition: all 0.15s ease-out;

      &:hover,
      &.active {
        background-color: #dbdbdb;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .page-nine {
    &.mb-10 {
      margin-bottom: 10%;
    }

    .list-occupation {
      width: pxToRem(510);
    }
  }

  .step-sever {
    .info-customer {
      max-width: pxToRem(630);

      .wForm-input {
        padding-left: pxToRem(15);
        padding-right: pxToRem(15);
      }
    }
  }

  .progressBar-page {

    .info-customer {
      max-width: pxToRem(600);
    }
  }

  .progressBar-group {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: pxToRem(40);

    .label {
      font-family: $fontLato;
      font-size: pxToRem(18);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.5;
      letter-spacing: normal;
      text-align: left;
      color: #212121;
    }

    img {
      width: pxToRem(20);
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .reset {
      opacity: 0.6;
    }

    .progress {
      width: pxToRem(230);
      height: pxToRem(7);

      .progress-bar {
        border-radius: 3.5px;
        background-color: #2e9fe9;
      }
    }
  }
}

.modal.show .modal-dialog {
  transform: none;
  transform: translate(0, -50%);
  top: 45%;
}

.modal-dialog {
  min-height: auto;
  background-color: #ffffff;
  border-radius: pxToRem(10);

  &.modal-md {
    max-width: pxToRem(580);
  }

  &.modal-xl {

    .modal-footer {
      justify-content: center;
      padding-top: pxToRem(40);

      .btnPrimary {
        background-color: $btn-bg;
        color: $white;
        border-color: $btn-bg;

        &:hover {
          background-color: $black-active;
          border-color: $black-active;
        }
      }
    }

    .modal-content {
      padding-bottom: pxToRem(60);
      padding-right: pxToRem(22);

      .modal-body {
        max-height: pxToRem(450);
        overflow: auto;

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
          background-color: transparent;
        }

        &::-webkit-scrollbar {
          width: pxToRem(8);
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-light13;
          border: pxToRem(8) solid $color-light13;
          border-radius: pxToRem(4);
        }

        .content-policy {
          padding: pxToRem(30);
          padding-top: pxToRem(20);

          * {
            text-align: justify !important;
            color: #001d30;
            letter-spacing: pxToRem(-0.45);
            line-height: 1.8;
            font-family: $fontLato;
            font-size: pxToRem(18);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
          }

          p {
            margin-bottom: pxToRem(25);
          }

          ul {
            list-style-type: disc;
            padding-left: pxToRem(50);
            margin-bottom: pxToRem(25);
          }
        }
      }
    }
  }

  .modal-content {
    padding: pxToRem(13) pxToRem(45) pxToRem(76) pxToRem(45);
    position: relative;
    border: 0;

    .modal-header {
      border: 0;

      button {
        top: pxToRem(20);
        right: pxToRem(30);
        width: pxToRem(25);
        height: pxToRem(30);
        font-size: pxToRem(35);
        position: absolute;
        padding: 0;
      }
    }

    .modal-body {
      border: 0;
      padding: 0;
      padding-bottom: pxToRem(35);
      text-align: center;

      h4 {
        font-size: pxToRem(30);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #001d30;
        margin-bottom: pxToRem(10);
      }

      p {
        font-family: $fontLato;
        font-size: pxToRem(18);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.22;
        text-align: left;
        color: #212121;
        letter-spacing: (-0.45);
        text-align: center;
      }

    }
  }

  .modal-footer {
    padding: 0;
    border: 0;
    text-align: center;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: pxToRem(210);
      height: pxToRem(58);
      padding: pxToRem(10) pxToRem(25);
      border-radius: pxToRem(4);
      background-color: #e2e2e2;
      text-align: center;
      font-family: $fontLato;
      font-size: pxToRem(19);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #212121;
      text-transform: uppercase;
      border-color: #e2e2e2;
      margin: pxToRem(0) pxToRem(10);

      &.bnt-right {
        color: $white;
        background-color: $btn-bg;
        border-color: $btn-bg;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: $black-active;
        border-color: $black-active;
        color: $white;
      }
    }

    .btnPrimary {}
  }
}

.loadingLifeInsurance {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    font-style: normal;
    color: #2e9fe9;
    margin-left: 0.3125rem;
    font-size: 20px;
  }
}

.server-statement {
  display: none;

  p {
    text-align: center;

    span {
      color: #cdcdcd !important;
      font-size: pxToRem(14) !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.modal-popup-footer {
  p {
    text-align: left !important;
  }
}

// Mobile
.life-insurance {
  @media screen and (max-width: 1200px) {
    .welcomeLifeInsurance {
      margin-top: pxToRem(50);

      .logo {
        margin-bottom: pxToRem(100);
      }

      h1 {
        font-size: pxToRem(30);
        margin-bottom: pxToRem(20) !important;
      }

      p {
        font-size: pxToRem(20);
      }
    }
  }

  @media screen and (max-width: 640px) {
    .footer-life-insurance {
      display: none !important;

      &.position-relative,
      &.footer-absolute {
        display: block !important;
      }
    }

    .life-insurance__MainCt {
      padding-top: pxToRem(150);
    }

    .welcomeLifeInsurance {

      .logo {
        margin-bottom: pxToRem(60);
      }

      h1 {
        font-size: pxToRem(25);
        line-height: 1.2;
      }

      p {
        font-size: pxToRem(16);
        line-height: 1.2;
        padding: 0 pxToRem(10);
      }
    }

    .header-wrap {
      height: pxToRem(60);
      padding-top: pxToRem(10);
      padding-bottom: pxToRem(10);

      .logo {
        max-width: pxToRem(89);

        img {
          height: pxToRem(40);
        }
      }

      .header-humanoid {
        width: pxToRem(50);
        height: pxToRem(50);
        margin-left: pxToRem(-20);
        bottom: pxToRem(-25);

        .avatar.med {
          width: pxToRem(50);
          height: pxToRem(50);
          background-size: pxToRem(50) pxToRem(50);
        }
      }

      .btn-reset {
        right: pxToRem(15);
      }
    }

    h2 {
      font-size: pxToRem(25);
      line-height: 1.2;

      span {
        font-size: pxToRem(18);
      }

      br {
        display: contents;
      }

      margin-bottom: pxToRem(35);
    }

    .formContent-life-insurance p {
      font-size: 16px;
      line-height: 1.4;
    }

    .btnPrimary.life {
      // height: pxToRem(55);
      font-size: pxToRem(18);
      width: auto;
    }

    // InputCustom2 
    .input {
      &__box {

        &.email {
          .input-change {
            padding-left: pxToRem(45);
          }

          .input__label {
            left: pxToRem(46);
          }
        }

        input,
        textarea {
          height: pxToRem(55);
          border-radius: pxToRem(6);
          font-size: pxToRem(18);
          padding: pxToRem(10);
          padding-bottom: pxToRem(6);

          &.value {
            padding-top: pxToRem(19);
          }
        }

        textarea {
          padding-top: pxToRem(18);

          &.value {
            padding-top: pxToRem(22);
            font-size: pxToRem(15);
          }
        }

        .input__label {
          top: pxToRem(8);
          left: pxToRem(13);
          -webkit-transform: translateY(10px);
          -moz-transform: translateY(10px);
          -ms-transform: translateY(10px);
          -o-transform: translateY(10px);
          transform: translateY(10px);
          font-size: pxToRem(13);
          max-width: pxToRem(280);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;

          &.active {
            font-size: pxToRem(14);
            transform: scale(0.75);
            top: pxToRem(3)
          }

          &.multiline {
            top: pxToRem(8);

            &.active {
              top: pxToRem(4);
            }
          }

          &.font-mobile12 {
            font-size: pxToRem(11);
          }
        }
      }
    }

    .formContent-step-four {
      .info-customer {
        margin-top: pxToRem(10) !important;

        .group-birth {
          .birth-label {
            padding: pxToRem(5) pxToRem(15);
            font-size: pxToRem(15);
            height: pxToRem(50);
          }

          .input {
            width: pxToRem(84);

            .input__label {
              left: 50%;
              transform: translate(-50%, 9px);

              &.active {
                top: pxToRem(-6);
              }
            }

            .input-change {
              height: pxToRem(50);
            }
          }
        }
      }
    }

    .text-error {
      font-size: pxToRem(14);
    }

    .form-six {
      .wForm-input {
        padding-right: 0 !important;
      }
    }

    .list-occupation {
      width: 100% !important;

      li {
        padding: pxToRem(10) pxToRem(10) pxToRem(10) pxToRem(43);
        line-height: 1.4;
      }
    }

    .progressBar-group {
      display: block;
      margin-bottom: pxToRem(15);

      .progress {
        width: 100%;
      }

      img {
        margin-top: pxToRem(-6);
        top: 100%;
      }
    }

    .icon.check-phone {
      img.iconEmail {
        width: pxToRem(15);
      }
    }
  }
}

.icon.check-phone {
  z-index: -1;
}

.sub {
  font-size: pxToRem(16) !important;
  position: relative;
  display: inline-block;
  // justify-content: center;
  text-align: center;
  max-width: pxToRem(760);
  margin: auto;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    +.tooltip-price {
      visibility: visible;
      opacity: 1;
    }
  }

  img {
    display: inline-block;
    vertical-align: middle;
    width: pxToRem(19);
    height: pxToRem(19);
    margin-left: pxToRem(8);
    cursor: pointer;
  }
}

.dot-tooltip {
  display: inline-block;

  .w-400 {
    &.tooltip-price {
      max-width: pxToRem(415) !important;

      .tooltip-desc {
        max-width: pxToRem(415) !important;
      }
    }
  }
}

div.tooltip2 {
  .tooltip-price {
    max-width: pxToRem(510);

    .tooltip-desc {
      max-width: pxToRem(510);

      &:before,
      &::after {
        left: 50%;
      }
    }
  }
}

.tooltip-price {
  position: absolute;
  top: pxToRem(50);
  max-width: pxToRem(760);
  left: 50%;
  right: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  @include transform-translate(-50%, 0);

  .tooltip-desc {
    padding: pxToRem(20);
    margin-bottom: pxToRem(50);
    background-color: #fff;
    border-radius: pxToRem(10);
    border: 2px solid #9aa2a8;
    border-radius: 7px;

    &:before,
    &::after {
      bottom: 100%;
      left: 68%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: #9aa2a8;
      border-width: 10px;
    }

    &::after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #fff;
      border-width: 10px;
      margin-bottom: -3px;
    }

    p {
      color: #001d30;
      font-size: pxToRem(13);
      line-height: pxToRem(20);
      text-align: left;
      font-weight: 600;
      margin-bottom: 0;

      span {
        font-weight: 600;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.font-italic {
        font-style: italic;
        font-size: 12px;
      }
    }

    .text-red {
      color: #ee1111;
    }
  }
}

@media screen and (max-width: 640px) {
  .page-nine {
    padding-bottom: pxToRem(60);
    position: relative;
    top: pxToRem(-40);
  }

  .server-statement {
    display: block;
  }

  .modal-dialog {
    min-height: auto;

    &.modal-xl {

      .modal-footer {
        padding-top: pxToRem(20);
      }

      .modal-content {
        padding-bottom: pxToRem(30);
        padding-right: pxToRem(22);

        .modal-body {
          max-height: pxToRem(350);
          overflow: auto;

          .content-policy {
            padding: pxToRem(15);

            * {
              line-height: 1.4;
              font-family: $fontLato;
              font-size: pxToRem(16);
            }

            p {
              margin-bottom: pxToRem(20);
            }

            ul {
              list-style-type: disc;
              padding-left: pxToRem(30);
              margin-bottom: pxToRem(20);
            }
          }
        }
      }
    }

    .modal-content {
      padding: pxToRem(30);

      .modal-header {
        padding: 0;

        button {
          top: pxToRem(15);
          right: pxToRem(25);
        }
      }

      .modal-body {
        text-align: center;

        h4 {
          font-size: pxToRem(20);
        }

        p {
          font-size: pxToRem(16);
          line-height: 1.2;
        }
      }

      .modal-footer {
        padding-left: 0;
        padding-right: 0;

        button {
          width: 45%;
          margin: 0 pxToRem(5);
          height: pxToRem(50);
          padding: pxToRem(5) pxToRem(10);
          font-size: pxToRem(16);
        }
      }
    }
  }

  .tooltip-price {
    display: none;

    .tooltip-desc {
      padding: pxToRem(15);
    }
  }

  .sub {

    &:hover {
      .tooltip-price {
        display: block;
      }
    }
  }
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(00deg);
  }
}

.progressBar-group {
  img.reset {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    right: pxToRem(-10);
  }
}

.server-policy {
  p {
    line-height: 1.4 !important;
    font-size: pxToRem(12) !important;
    text-align: center;
    font-family: $fontLato;
    font-weight: $regular;
    font-style: normal;

    span {
      color: $btn-bg;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        text-decoration: underline;
      }
    }

  }
}

.server-policy {
  max-width: pxToRem(615);
  margin: auto;
}

.covid19 {
  box-shadow: 0px pxToRem(10) pxToRem(40) pxToRem(-10) rgb(46 159 233 / 36%);
  background-color: #2e9fe9;
  color: #fff;
  border-color: #2e9fe9;
  font-size: pxToRem(20);
  line-height: 1.1;
  font-family: "Lato";
  font-weight: 900;
  text-transform: uppercase;
  width: pxToRem(400);
  min-width: 12.5rem;
  margin: 3.125rem auto 0 auto;
  transition: all 0.2s, transform 0.5s;
  border-radius: pxToRem(20);
  padding: pxToRem(3) pxToRem(10);
  visibility: visible;
  @media screen and (max-width: 640px) {
    font-size: pxToRem(16);
    width: pxToRem(320);
  }
}

.text-select {
  font-size: 25px;
  font-weight: 700;
  font-family: "Roboto Slab";
}

.refinance-fb {
  .btn-group-gender {
    .btn-box {
      width: 165px;
      height: 165px;
      border-radius: 7px;
      margin: auto;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 640px) {
        width: 135px;
        height: 140px;
      }
    }

    .item {
      text-align: center;

      p {
        margin: 0;
        font-size: pxToRem(18);
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
}

.group-input-rating {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .tooltip-range {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .btn-miss {
    max-width: 75px;
    position: relative;
    bottom: -21px;
  }

  @media screen and (max-width: 640px) {
    .tooltip-range {
      padding-left: 10px;
      padding-right: 10px;
    }

    .btn-miss {
      max-width: 55px;
      bottom: -18px;
    }
  }
}

.question-left {
  display: flex;
  align-items: center;

  @media screen and (max-width: 640px) {
    display: block;

    .title-question-left {
      width: 100%;
      padding: 0;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .question-right {
      width: 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.title-question-left {
  width: 52%;
  padding: 0 3%;
}

.question-right {
  width: 48%;
  max-width: 48%;
}
.contactUsThank {
  .modal-dialog .modal-content {
    padding: 15px;
    .refinanceThank {
      &__title {
        font-size: 2.2rem;
      }
      &__desc {
        margin-top: 15px;
        line-height: 1.4;
      }
    }
  }
}
