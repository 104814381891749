@font-face {
  font-family: 'Lato';
  src: url('./Lato-Light.eot');
  src: url('./Lato-Light.eot?#iefix') format('embedded-opentype'), url('./Lato-Light.woff2') format('woff2'), url('./Lato-Light.woff') format('woff'), url('./Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./Lato-Regular.eot');
  src: url('./Lato-Regular.eot?#iefix') format('embedded-opentype'), url('./Lato-Regular.woff2') format('woff2'), url('./Lato-Regular.woff') format('woff'), url('./Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}