.checkbox {
  .checkbox__input {
    display: none;
  }

  .terms {
    font-size: pxToRem(20);
    font-weight: normal;
    line-height: 1.47;
    letter-spacing: pxToRem(-0.54);
    text-align: left;
    font-family: $fontLato;
    font-weight: $regular;
    font-style: normal;
    color: $btn-bg;
    margin-left: pxToRem(5);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: 640px) {
      font-size: pxToRem(16);
    }
  }

  .checkbox__label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: pxToRem(18);
    line-height: pxToRem(40);
    padding-left: pxToRem(50);

    span {
      font-size: pxToRem(18);
      font-weight: normal;
      line-height: 1.47;
      letter-spacing: pxToRem(-0.54);
      text-align: left;
      font-family: $fontLato;
      font-weight: $regular;
      color: $black-active;
    }

    &::before {
      height: pxToRem(28);
      width: pxToRem(28);
      border: pxToRem(1) solid #b0b0b0;
      left: pxToRem(-1);
      top: 0;
      position: absolute;
      content: '';
      display: inline-block;
      background-color: $white;
      margin-top: pxToRem(5);
      border-radius: pxToRem(4);
      box-shadow: 0px 10px 40px -10px rgba($btn-bg, 0.36);

    }

    &::after {
      position: absolute;
      display: inline-block;
      height: pxToRem(7);
      width: pxToRem(13);
      border-left: pxToRem(2) solid $white;
      border-bottom: pxToRem(2) solid $white;
      color: $black-active;
      transform: rotate(-45deg);
      content: none;
      left: pxToRem(7);
      top: pxToRem(13);
      opacity: 0;
    }

    @media screen and (max-width: 640px) {
      padding-left: pxToRem(35);
      line-height: pxToRem(31);

      span {
        font-size: pxToRem(16);
      }

      &::before {
        height: pxToRem(22);
        width: pxToRem(22);
        left: pxToRem(-1);
        margin-top: pxToRem(5);
      }

      &::after {
        height: pxToRem(6);
        width: pxToRem(10);
        left: pxToRem(5);
        top: pxToRem(11);
      }

    }
  }

  &.checkbox--checked {
    .checkbox__label::before {
      background-color: $btn-bg;
      border-color: $btn-bg;
    }

    .checkbox__label::after {
      content: '';
      opacity: 1;
      color: $white;
    }
  }
}